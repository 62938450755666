import { PageLayout } from "@components";
import React from "react";
import { Demo } from ".././biopharmaceutical";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { PartnersV2 } from "@components/LimsSoftware/PartnersV2";
import classNames from "classnames";
import { LogosAndG2Section } from "@components/Solutions/LogosAndG2Section";

export default function Research() {
	const demoProps = {
		title: "Best modern software for streamlining cell therapy experiments",
		subtitle:
			"Unlock the potential of life-saving cell therapeutic R&D with Genemod’s diverse LIMS features, such as inventory advanced search, electronic lab notebook, and AI platform.",
		srcImg: "man-do-sample-test-in-lab.png",
		disableBackgroundShapes: true,
		rightSectionStylesOverride: styles.rightSectionStylesOverride,
		getADemoOverride: classNames(
			styles.getADemoOverride,
			styles.clinicalGetADemoOverride
		),
		titleStylesOverride: styles.clinicalDemoTitleStylesOverride,
		subtitleStylesOverride: styles.clinicalDemoSubtitleStylesOverride,
		leftSectionOverride: styles.clinicalDemoLeftSectionOverride,
	};

	const cards: FeatureCardProps[] = [
		{
			title: "Pioneer innovations in cell therapeutics with Genemod",
			list: [
				"Efficiently manage cell samples using our intuitive virtual freezer interface, enhancing the precision of cell therapeutic research.",
				"Manage and track vital consumables for cell therapy research with Genemod's inventory system.",
				"Record and organize cell therapy research findings efficiently with Genemod's electronic lab notebook.",
			],
			image: (
				<StaticImage
					src={
						"../../../assets/images/landing_page/products_inventory.png"
					}
					alt="Easily Manage and Submit Orders"
				/>
			),
			className: styles.featureQuickAccess,
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
		{
			title: "Next-generation cell therapeutics powered by Genemod",
			list: [
				"Genemod's LIMS centralizes laboratory data management, covering aspects like research data handling, sample tracking, result documentation, quality control, and data processing.",
				"Efficiently store, organize, and document research data and reports. Facilitate team collaboration on a centralized platform, minimizing time spent on redundant processes.",
				"Genemod integrates ChatGPT in our electronic lab notebook, enabling report revisions at a click and providing instant information through our Genemod AI chat bot.",
			],
			image: (
				<StaticImage
					src={"../../../assets/products/single-source-of-truth.png"}
					alt="Collaborate for In-depth Order Tracking"
				/>
			),
			orientation: "right",
			className: styles.featureQuickAccess,
			sublistColor: "text-helper",
			listItemWeight: "SEMIBOLD",
			titleStylesOverride: styles.featureTitleStylesOverride,
		},
	];
   
	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "research" }}>
			<Margins className={styles.demoMargins}>
				<Demo {...demoProps} />
			</Margins>
			<Margins className={styles.featureMargins}>
				<FeatureCardLayout className={styles.featuresLayout}>
					{cards.map((card, index) => {
						return <FeatureCard {...card} key={index.toString()} />;
					})}
				</FeatureCardLayout>
			</Margins>
			<LogosAndG2Section/>
			<Margins className={styles.institutionsMargins}>
				<JoinInstitutionsV2
					outerContainerClassName={
						styles.institutionsOuterContainerClassName
					}
				/>
			</Margins>
		</PageLayout>
	);
}
